/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { splitStringToParagraphs } from "~utils/helpers";

const WorkPage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;
  const projects = [];

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    projects.push(node);
  });

  //

  projects.sort((a, b) => {
    const priorityA = a.frontmatter.priority;
    const priorityB = b.frontmatter.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="work-page w-full relative pt-24">
        <div className="grid">
          <ul className="grid-end-12 flex flex-wrap pt-8">
            {projects.map((project, projectIndex) => {
              let xsWidthClass;

              switch (project.frontmatter.imageSize) {
                case `xl`:
                  xsWidthClass = `xs:w-full`;
                  break;

                case `l`:
                  xsWidthClass = `xs:w-full`;
                  break;

                case `m`:
                  xsWidthClass = `xs:w-full`;
                  break;

                case `s`:
                  xsWidthClass = `xs:w-full`;
                  break;

                default:
                  xsWidthClass = `w-full`;
                  break;
              }

              return (
                <li
                  key={project.frontmatter.title}
                  className={`work-page__project work-page__project--${project.frontmatter.imageSize} w-1/3 ${xsWidthClass} relative block xs:mt-24 mb-12 mr-4 xs:mx-auto`}
                >
                  <Link
                    to={project.fields.slug}
                    className="animation-appear-right-slow w-full h-full relative flex flex-col items-stretch"
                    onMouseEnter={() =>
                      setBackground(project.frontmatter.backgroundColor)
                    }
                    onMouseLeave={() => setBackground(`white`)}
                    style={{ animationDelay: `${projectIndex * 100 + 300}ms` }}
                  >
                    {(project?.frontmatter?.image?.childImageSharp?.fluid && (
                      <figure className="w-full h-full relative overflow-hidden">
                        <Img
                          className="w-full h-full unbound object-cover transform-center"
                          fluid={
                            project.frontmatter.image.childImageSharp.fluid
                          }
                          alt={project.frontmatter.title}
                        />
                      </figure>
                    )) || (
                      <>
                        {project?.frontmatter?.bannerImage?.childImageSharp
                          ?.fluid && (
                            <figure className="w-full h-full relative overflow-hidden">
                              <Img
                              className="w-full h-full unbound object-cover transform-center"
                                fluid={
                                project.frontmatter.bannerImage.childImageSharp
                                    .fluid
                                }
                                alt={project.frontmatter.title}
                              />
                            </figure>
                          )}
                      </>
                    )}

                    <div className="relative">
                      <h2 className="work-page__project__title w-full mt-2 mx-auto caption caption--serif text-left">
                        {project.frontmatter.title}
                      </h2>

                      {project?.frontmatter?.description &&
                        splitStringToParagraphs(
                          project.frontmatter.description,
                          `w-full mx-auto caption caption--serif text-left`
                        )}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </Layout>
    </>
  );
};

export default WorkPage;

export const query = graphql`
  query WorkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { in: ["project-page", "site-page"] } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            priority
            backgroundColor
            description
            bannerImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            imageSize
          }
        }
      }
    }
  }
`;
